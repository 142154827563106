<template>
  <div class="main gray-bg">
    <van-nav-bar class="top-bar has-bg-bar" title="活动" left-arrow @click-left="onClickLeft"/>
    <van-list class="notice-list" v-model:loading="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="getActivityList">
      <van-cell v-for="(item, key) in list" :key="key" class="notice-cell" clickable :to="'/personal/activity-detail/'+item.id" is-link>
        <template #title>
          <div class="van-ellipsis notice-title">{{ item.title }}</div>
          <div class="notice-time">{{date(item.create_time, 'Y-m-d H:i:s')}}</div>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>

<script>

import {NavBar,Cell, List} from "vant";
import {reactive, ref} from "vue";
import {activityList} from "@/api/game";
import {date} from "@/utils/function";
import {getTime} from "@/utils/make";
import {exitPage} from "@/utils/callback";

export default {
  name: "ActivityList",
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [List.name]: List,
  },
  setup() {
    const list = ref([])
    const query = reactive({
      last: 0,
      limit: 10
    })
    const loading = ref(false);
    const finished = ref(false);
    const onClickLeft = () => {
      exitPage()
    };
    const getActivityList = () => {
      loading.value = true;
      let oldList = list.value;
      activityList(query).then(({data}) => {
        loading.value = false;
        list.value = [...oldList,...data.list];
        query.last = data.last
        if (data.list.length < query.limit || data.last == 0) {
          finished.value = true;
        }
      })
    }
    return {
      list,
      loading,
      finished,
      onClickLeft,
      getActivityList,
      date,
      getTime,
    }
  }
}
</script>

<style scoped>
.top-bar :deep(.van-nav-bar__content) {
  background: url("../../assets/images/personal/msg-bar.png") bottom center no-repeat;
  background-size: 100% auto;
}
.notice-list{
  padding:0px 16px;
}
.notice-cell{
  margin:12px auto;
  padding:10px 16px;
  border-radius: 4px;
  height: 68px;
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
}
.notice-cell :deep(.van-cell__title){
  text-align: left;
}
.notice-title{
  font-size: 15px;
  font-weight: 500;
  color: #1D2129;
  line-height: 22px;
}
.notice-time{
  margin-top:8px;
  font-size: 12px;
  font-weight: 400;
  color: #86909C;
  line-height: 12px;
}
</style>
